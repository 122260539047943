<template>
  <div class="severtemplate-create-server">
    <page-header :title="pageTitle" />
    <a-form :form="form.fc" class="mt-3 mb-100"  v-bind="formItemLayout">
      <a-form-item label="名称">
        <a-input v-decorator="decorators.name" maxlength="50" />
      </a-form-item>
      <a-form-item label="内容">
        <rich-editor ref="richEditorRef" v-decorator="decorators.content" :toolbarConfig="toolbarConfig" style="min-height:600px;" />
      </a-form-item>
      <a-form-item label="附件" v-bind="formItemLayout" :help="$t('compute.text_671')">
        <a-upload :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload" @preview="handleDownload">
          <a-button> <a-icon type="upload" />{{$t('compute.text_245')}}</a-button>
        </a-upload>
      </a-form-item>
      <page-footer>
        <template v-slot:right>
          <a-button size="large" type="primary" class="mr-2" @click="submit" style="width: 120px;" :loading="loading">保存</a-button>
          <a-button size="large" style="width: 120px;" @click="goBack">{{$t('compute.text_135')}}</a-button>
        </template>
      </page-footer>
    </a-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WindowsMixin from '@/mixins/windows'
import RichEditor from '@/components/RichEditor'
import { downloadFile, download } from '~/utils/utils'
export default {
  name: 'PortalCreate',
  components: {
    RichEditor,
  },
  mixins: [WindowsMixin],
  data () {
    return {
      pageTitle: '新建',
      loading: false,
      form: {
        fc: this.$form.createForm(this),
        fd: {
          id: '',
          name: '',
          content: '',
        },
      },
      decorators: {
        id: ['id'],
        name: [
          'name',
          {
            rules: [
              { required: true, message: '请输入名称' },
              { max: 50, message: '名称不能超过50字' },
            ],
          },
        ],
        content: [
          'content',
          {
            rules: [
              { required: true, message: '请输入内容' },
            ],
          },
        ],

      },
      formItemLayout: {
        wrapperCol: {
          md: { span: 18 },
          xl: { span: 21 },
          xxl: { span: 22 },
        },
        labelCol: {
          md: { span: 6 },
          xl: { span: 3 },
          xxl: { span: 2 },
        },
      },
      toolbarConfig: {
        toolbarKeys: [
          'bold', 'underline', 'italic', 'through', 'code', 'sub', 'sup', 'clearStyle', 'color',
          'bgColor', 'fontSize', 'fontFamily', 'indent', 'delIndent',
          'justifyLeft', 'justifyRight', 'justifyCenter', 'justifyJustify',
          'lineHeight', 'insertImage', 'deleteImage', 'editImage', 'viewImageLink',
          'imageWidth30', 'imageWidth50', 'imageWidth100', 'divider',
          'insertLink', 'editLink', 'unLink', 'viewLink', 'codeBlock', 'blockquote', 'headerSelect', 'header1',
          'header2', 'header3', 'header4', 'header5', 'todo', 'redo', 'undo', 'fullScreen', 'bulletedList',
          'numberedList', 'insertTable', 'deleteTable', 'insertTableRow', 'deleteTableRow', 'insertTableCol',
          'deleteTableCol', 'tableHeader', 'tableFullWidth',
          'insertVideo', 'codeSelectLang',
        ],
      },
      accept: 'image/*, audio/*, video/*, .md,.txt,.doc,.docx,.xls,.xlsx,.xlsm,.pdf,.zip,.rar,.7z,.csv',
      fileList: [],
      defaultFileNames: [],
      delFileList: new Set(),
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  watch: {
    $route (newValue, oldValue) {
      this.fetchDetail()
    },
  },
  created () {
    this.fetchDetail()
  },
  methods: {
    handleRemove (file) {
      if (this.defaultFileNames.includes(file.name)) {
        this.delFileList.add(file.name)
      }
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload (file) {
      this.fileList = [...this.fileList, file]
      return false
    },
    handleUploadChange ({ file, fileList }) {
      this.fileList = fileList
    },
    fetchDetail () {
      if (!this.$route.query.id) {
        this.form.fd = {
          id: '',
          name: '',
          content: '',
        }
        this.pageTitle = '新建'
        return
      } else {
        this.pageTitle = '编辑'
      }
      new this.$Manager('announcements', 'v1')
        .get({ id: this.$route.query.id })
        .then(({ data }) => {
          this.$nextTick(() => {
            console.log('data=', data, this.$refs.richEditorRef)
            this.form.fc.setFieldsValue({
              id: data.id,
              name: data.name,
              content: data.content,
            })
            this.form.fd.id = data.id
            this.form.fd.name = data.name
            this.form.fd.content = data.content
            this.$refs.richEditorRef.html = data.content
            // 回显附件
            const annexs = data.annexs
            this.defaultFileNames = annexs
            if (annexs) {
              this.fileList = annexs.split(',').map(item => {
                const name = item.includes('@@@') ? item.split('@@@')[1] : item
                return {
                  uid: 'upload-' + data.id,
                  name: name,
                  status: 'done',
                  url: data.cdn_uri + '/' + data.name + '/' + item,
                }
              })
            } else {
              this.fileList = []
            }
          })
        })
        .catch(() => {
          this.$message.error('获取详情失败')
          setTimeout(() => {
            this.goBack()
          }, 1500)
        })
    },

    async saveOrUpdate (data) {
      try {
        if (this.form.fd.id) {
          // return new this.$Manager('announcements').update({ id: this.form.fd.id, data })
          return this.$http({
            method: 'PUT',
            url: `/v1/announcement/updates/${this.form.fd.id}`,
            data,
          })
        } else {
          // return new this.$Manager('announcements').create({ data })
          return this.$http({
            method: 'POST',
            url: '/v1/announcement/creates',
            data,
          })
        }
      } catch (error) {
        throw error
      }
    },
    async submit () {
      // console.log(this.$refs.richEditorRef)
      const text = this.$refs.richEditorRef.editor.getText()
      if (!text) {
        this.$message.error('请输入内容')
        return
      }
      this.loading = true
      try {
        const { fileList } = this
        const formData = new FormData()
        const values = await this.form.fc.validateFields()
        console.log('提交数据：', values, fileList)
        if (this.form.fd.id) {
          formData.append('id', this.form.fd.id)
          if (this.delFileList.size > 0) {
            const delFileArr = Array.from(this.delFileList)
            formData.append('del_files', delFileArr.join(','))
          }
        }
        formData.append('name', values.name)
        formData.append('content', values.content)
        formData.append('domain_id', this.userInfo.projectDomainId)
        formData.append('owner_id', this.userInfo.id)
        if (fileList.length > 0) {
          fileList.forEach(file => {
            console.log('file=', file)
            if (file instanceof File) {
              formData.append('files', file)
            }
          })
        }
        const res = await this.saveOrUpdate(formData)
        // console.log('res=', res)
        if (res.data && res.data.id) {
          const msg =
            this.form.fd.id
              ? '编辑成功'
              : '新增成功'
          this.$message.success(msg)
          this.goBack()
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        throw error
      }
    },
    goBack () {
      this.$router.back()
    },
    handleDownload (file) {
      // console.log('file', file)
      if (file instanceof File) {
        download(file, file.name)
      } else {
        downloadFile(file.url, file.name)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.severtemplate-create-server {
  ::v-deep .ant-form.ant-form-horizontal .ant-form-item .ant-form-item-label{
    padding-left: 20px;
  }
}
.mb-100{
  margin-bottom: 100px;
}
</style>
