// import { getEnabledSwitchActions } from '@/utils/common/tableActions'
import i18n from '@/locales'
export default {
  created () {
    this.singleActions = [
      {
        label: '修改',
        permission: 'announcements_update',
        action: row => {
          if (row.enabled) {
            this.$message.warning('已公示的公告不能修改，需撤销后才可以修改！')
            return
          }
          // 跳转详情
          this.$router.push({
            path: '/bidding-mng/createOrUpdate',
            query: {
              id: row.id,
            },
          })
        },
      },
      {
        label: i18n.t('system.text_153'),
        actions: row => {
          return [
            {
              label: '发布',
              permission: 'announcements_perform_enable',
              action: () => {
                this.onManager('performAction', {
                  id: row.id,
                  managerArgs: {
                    action: 'enable',
                  },
                })
              },
              meta: row => {
                return {
                  validate: row && !row.enabled,
                }
              },
            },
            {
              label: '撤销',
              permission: 'announcements_perform_disable',
              action: () => {
                this.onManager('performAction', {
                  id: row.id,
                  managerArgs: {
                    action: 'disable',
                  },
                })
              },
              meta: row => {
                return {
                  validate: row && row.enabled,
                }
              },
            },
            {
              label: i18n.t('compute.perform_delete'),
              permission: 'announcements_delete',
              action: obj => {
                this.createDialog('DeleteResDialog', {
                  vm: this,
                  data: [obj],
                  columns: this.columns,
                  title: i18n.t('compute.perform_delete'),
                  onManager: this.onManager,
                  name: '删除',
                })
              },
              meta: obj => {
                if (obj.enabled) {
                  return {
                    validate: false,
                    tooltip: '已公示',
                  }
                } else {
                  return {
                    validate: true,
                    tooltip: '',
                  }
                }
              },
            },
          ]
        },
      },
    ]
  },
}
