<template>
  <div>
    <page-header title="公告配置" />
    <page-body>
      <list :id="listId" :menu="menu" :menuOptions="menuOptions" />
    </page-body>
  </div>
</template>

<script>
import List from './components/List'

export default {
  name: 'PortalIndex',
  components: {
    List,
  },
  data () {
    return {
      listId: 'AnnouncementList',
      menuOptions: [
        { label: '招投标中心配置', key: 'bidding-mng' },
      ],
      menu: '',
    }
  },
}
</script>
