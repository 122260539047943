<template>
  <div>
    <page-header title="产品配置" />
    <page-body>
      <div class="menu-container">
        <!-- <h1 class="top-title">门户产品详情配置</h1> -->
        <div class="main-box">
          <div class="left">
            <h2 class="title">1.选择菜单页面</h2>
            <p class="desc">已选择：{{name}}</p>
            <el-tree
              ref="treeRef"
              :current-node-key="id"
              :data="list"
              :default-expand-all="true"
              :expand-on-click-node="true"
              :props="defaultProps"
              node-key="id"
              @node-click="handleNodeClick">
              <template v-slot="{ node }">
                <div class="page-tree-node">
                  <span class="label">
                    <span class="text" :title="node.label">{{ node.label }}</span>
                  </span>
                </div>
              </template>
            </el-tree>
          </div>
          <div class="right">
            <h2 class="title">2.选择页面组件</h2>
              <a-select v-model="componentId" placeholder="请选择页面组件" @change="handleComChange">
                  <a-select-option
                  v-for="item in infoOptions"
                  :key="item.id"
                  :value="item.id">{{item.component_label}}</a-select-option>
              </a-select>
            <h2 class="title">3.配置页面组件</h2>
            <div>
              <a-button type="primary" @click="updateJsonData">更新配置</a-button>
            </div>
            <vueJsonEditor
              v-model="resultInfo"
              :showBtns="false"
              :mode="'code'"
              @json-change="onJsonChange"
              @json-save="onJsonSave"
              @has-error="onError" />
          </div>
        </div>
      </div>
    </page-body>
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor'
// import { list, getCfgInfo, updateCfgInfo } from '@/api/menu'
import { cloudProductMenus, mediaProductMenus, aiProductMenus } from '@/maps/common'
import http from '@/utils/http'

const compMap = {
  ProductBanner: '产品Banner',
  ProductSpec: '产品规格',
  ProductArchitecture: '产品架构',
  ProductSuperiority: '产品优势',
  ProductFunction: '产品功能',
  ApplicationScene: '应用场景',
  DocumentAndTool: '文档与工具',
}

const list = [
  {
    id: 2,
    name: '媒体服务',
    children: mediaProductMenus,
  },
  {
    id: 4,
    name: '人工智能',
    children: aiProductMenus,
  },
  {
    id: 3,
    name: '云计算基础',
    children: cloudProductMenus,
  },
]

export default {
  name: 'ProductMngIndex',
  components: {
    vueJsonEditor,
  },
  data () {
    return {
      id: '',
      name: '',
      list: list,
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      hasJsonFlag: true,
      resultInfo: { },
      infoOptions: [],
      componentId: undefined,
    }
  },
  mounted () {
  },
  methods: {
    getCfgInfo () {
      const menuId = this.id
      http.get('/v1/bill-web/menu/getCfgInfo', { params: { menu_id: menuId } }).then(response => {
        const res = response.data
        console.log('getCfgInfo res= ', res)
        if (res.code === 0) {
          const data = res.data
          if (data.length > 0) {
            const info = data[0].info
            console.log('info= ', info)
            this.infoOptions = info.map(item => ({
              component_label: compMap[item.component_name],
              ...item,
            }))
            // 根据组件顺序排序
            // this.componentList = info.filter(item => !whiteList.includes(item.component_name)).sort((a, b) => a.sort - b.sort);
            // console.log('this.componentList= ', this.componentList);
          }
        }
      })
    },
    updateCfgInfo (params) {
    //   updateCfgInfo({ id: 1, component_name: 'ProductBanner', sort: 2 }).then(res => {
      // updateCfgInfo(params)
      this.$http({
        method: 'POST',
        url: '/v2/menus/update-cfg-info',
        data: params,
      })
      // http.put('/v1/bill-web/menu/updateCfgInfo', params)
        .then(res => {
          console.log('updateCfgInfo res= ', res)
          this.$message.success('更新成功')
        })
    },
    handleNodeClick (data) {
      console.log(data)
      this.id = data.id
      this.name = data.name
      this.resultInfo = { }
      this.componentId = undefined
      this.infoOptions = []
      this.getCfgInfo()
    },
    handleComChange (value, _) {
      console.log(value, _)
      const json = this.infoOptions.find(item => item.id === value)
      console.log('json= ', json)
      try {
        this.resultInfo = JSON.parse(json.value)
      } catch (error) {
        this.resultInfo = {}
      }
    },
    updateJsonData () {
      if (!this.id) {
        this.$message.error('请选择页面')
        return
      }
      if (!this.infoOptions.length) {
        this.$message.error('请选择组件')
      }
      if (!this.resultInfo) {
        this.$message.error('请更新配置')
      }
      const json = this.infoOptions.find(item => item.id === this.componentId)
      if (json) {
        this.updateCfgInfo({ id: json.id, component_name: json.component_name, sort: json.sort, value: JSON.stringify(this.resultInfo) })
      }
    },
    onJsonChange (value) {
      console.log('更改value:', value)
      // 实时保存
      //   this.onJsonSave(value);
      this.resultInfo = value
    },
    onJsonSave (value) {
      // console.log('保存value:', value);
      this.resultInfo = value
      this.hasJsonFlag = true
    },
    onError (value) {
      // console.log("json错误了value:", value);
      this.hasJsonFlag = false
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-container {
  display: flex;
  flex-direction: column;
  height: 41.667vw;
  background: #fff;
  .top-title{
    text-align: center;
  }

  .title{
      color: #000;
  }

  .desc{
      color: #333;
  }

  .main-box{
    display: flex;
    .left {
      display: flex;
      flex-direction: column;
      width: 15.625vw;

    }

    .right {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

  }

}

::v-deep{
    .jsoneditor-outer{
        height: 31.25vw !important;
    }

}
</style>
